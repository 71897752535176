import Vue from "vue";
import Vuex from "vuex";
import api from '@/helpers/api'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

const PRIVACY_VERSION = "1.0"

Vue.use(Vuex)

const state = {
    lang: 'pt',
    darkMode: false,
    token: null,
    loading: false,
    firstRequest: false,
    sendingRequest: false,
    routeBeforeLogin: '/',
    routeBeforeRegister: '/',
    notification: null,
    privacy: '',
    login: {
        remember: false,
        email: null,
        password: null,
    },
    user: {
        name: null,
        roles: null,
        ID: null,
        bike: null,
        car: null,
    },
    offline: false,
    offlineCounter: 0,
    counter401: 0,
    cancelInstall: false,
    menuMode: "USER"
}

const mutations = {
    START(state) {
        state.loading = false;
        state.sendingRequest = false;
    },
    OFFLINE(state) {
        state.offline = true;
        state.offlineCounter++;
    },
    AUTH_ERROR(state) {
        state.counter401++;
    },
    ONLINE(state) {
        state.offline = false;
        state.offlineCounter = 0;
    },
    CHANGE_PASSWORD(state, password) {
        if (state.login.remember) {
            state.login.password = password;
        }
    },
    SET_NOTIFICATION(state, notification) {
        state.notification = notification;
    },
    CLEAR_NOTIFICATION(state) {
        state.notification = null;
    },
    SET_LOGIN(state, { response, email, password, remember }) {
        state.token = response.token;
        state.user.ID = response.ID;
        state.user.name = response.name;

        state.user.roles = response.roles;
        state.user.bike = response.bike;
        state.user.car = response.car;

        state.login.remember = remember;
        state.login.email = email;
        state.login.password = password;
    },
    CLEAR_LOGIN(state) {
        state.counter401 = 0;

        state.token = null;
        state.user.ID = null;
        state.user.name = null;

        state.user.roles = null;
        state.user.bike = null;
        state.user.car = null;

        state.login.remember = false;
        state.login.email = null;
        state.login.password = null;

        state.menuMode = "USER";
    },
    CHANGE_PREFERENCES(state, { car, bike }) {
        state.user.bike = bike;
        state.user.car = car;
    },
    LOADING(state, loading) {
        state.loading = loading;
    },
    SENDING_REQUEST(state, sendingRequest) {
        state.sendingRequest = sendingRequest;
    },
    FIRST_REQUEST(state, firstRequest) {
        state.firstRequest = firstRequest;
    },
    ACCEPT_PRIVACY(state) {
        state.privacy = PRIVACY_VERSION;
    },
    CANCEL_INSTALL(state) {
        state.cancelInstall = true;
    },
    SET_LANG(state, lang) {
        state.lang = lang;
    },
    SET_DARK_MODE(state, darkMode) {
        state.darkMode = darkMode;
    },
    CHANGE_MENU_MODE(state, menuMode) {
        state.menuMode = menuMode;
    }
}

const getters = {
    getLang: state => String(state.lang),
    getNotification: state => String(state.notification),
    isOffline: state => Boolean(state.offline),
    isCancelledInstall: state => Boolean(state.cancelInstall),
    privacyValid: state => Boolean(state.privacy === PRIVACY_VERSION),
    isDarkMode: state => Boolean(state.darkMode),
    isLogged: state => Boolean(state.token),
    getCounter401: state => Number(state.counter401),
    isLoading: state => Boolean(state.loading),
    isSendingRequest: state => Boolean(state.sendingRequest),
    isFirstRequest: state => Boolean(state.firstRequest),
    verifyRole: (state) => (role) => Boolean(state.token && state.user && state.user.roles.includes(role)),
    menuMode: state => String(state.menuMode),
}

const actions = {
    checkConnection({ commit, state }) {
        if (state.offline) {
            return new Promise((resolve, reject) => {
                api.get(
                    "api",
                    (response) => {
                        commit("ONLINE")
                        resolve(response.data)
                    },
                    (error) => {
                        commit("OFFLINE")
                        reject(error)
                    }
                );
            })
        }
    }
}

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
        }),],
    state,
    getters,
    mutations,
    actions
})